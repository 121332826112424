<template>
  <div class="smartDTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          目标责任制考核系统
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="mainFunction">
        <IntroduceCare
          :fontDesc="mainFunction"
          class="mainFunctionCard"
        ></IntroduceCare>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">系统功能图</h4>
        <span class="line"></span>
        <img src="@/assets/product/productMbzrz/gnt.png" alt="" style="width:1000px;margin-top:30px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">系统价值优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeft">
            <img :src="item.hoverScr" alt="" class="valueLeftHover">
            <div class="valueRight">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductMbzrz',
  data () {
    return {
      valueList: [
        { content: '不同单位分类考核与评价', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '定量考核与定性考核相结合', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '目标制定和结果达成相结合', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '过程评价与结果考核相结合', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ],
      mainFunction: [
        {
          title: '目标责任制考核系统',
          fontLeft: false,
          p: [
            {
              desc: '以实现单位发展规划为目标，以两级管理为基础，实行任期目标责任制，对二级单位目标管理工作进行全面考核，从而优化单位管理机制、提升管理效能。'
            }
          ],
          src: require('@/assets/product/productMbzrz/yjcx.png')
        }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moProductMbzrz')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.smartDTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/productMbzrz/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.mainFunction{
width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 60px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemArchitecture{
    width: 100%;
height: 870px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.systemValue{
     width: 100%;
height: 576px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  height: 148px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 40px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueLeft{
  width: 68px;
}
.valueLeftHover{
  width: 68px;
  display: none;
}
.valueRight{
  font-size: 24px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  margin-left: 20px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueRight{
    color: #fff;
  }
  .valueLeftHover{
    display: block;
  }
  .valueLeft{
    display: none;
  }
}
</style>
